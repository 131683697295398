body{
    background-color: #f7f7f7;
}

.root-container{
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.text-center {
    text-align: center!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-center img{
    width: 5rem
}

.items-top{
    margin-top: 20px;
}

.footer{
    margin-top: 40px;
}

.mainContainer{
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
}