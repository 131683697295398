.defbutton{
    display: flex;
    width: 3rem;
    height: 3rem;
    max-width:3rem;
    max-height:3rem;
    box-shadow: 1px 1px 9px 1px #707070;
    align-items: center;
    justify-content: center;
    border-radius:.35rem
}

.defbutton button{
    width:inherit;
    height:inherit;
    color:inherit;
    min-width:unset;
    min-height:unset
}
.defbutton button:hover{
    background-color:inherit;
    filter: brightness(.9);
    border: inherit;
}

.defbutton svg {
    font-size: 2rem;
    color:inherit
}