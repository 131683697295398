.loader-default-class{
    position: absolute;
    display: flex;
    padding: .75rem 1rem;
    width: 10rem;
    border-radius: .25rem;
    background-color: #ffffff;
    flex-direction: column;
    align-items: center;
    box-shadow: 1px 1px 10px 1px #a8a8a8;
}

.loader-external{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#03030317;
    z-index:1
}

.loader-external.fixed{
    position:fixed;
    z-index:1500;
    top:0;
    left:0
}

