* {
  margin: 0;
  padding: 0;
}

textarea{
  text-wrap:wrap
}

.overlay{
  z-index: 1;
  position:fixed;
  top:0;
  left:0;
  display:flex;
  width:max-content
}

.inside-menu img{
  max-width: 8rem;
  max-height: 8rem;
}

.backdrop {
  flex: 1;
  width:100svw;
  background-color: rgb(0 0 0 / 40%);
}

.lateral-menu{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  max-width: 9rem;
}

.inside-menu{
  display:flex;
  flex-direction: column;
  gap:1rem;
  height: fit-content;
  overflow: auto;
}

.main-menu-button.hamburguer svg{
  font-size:50px;
  width:5rem;
  height:4.5rem
}

.main-menu-bottom {
  height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.main-menu-button{
  display:flex;
  flex-direction: column;

}

.main-menu-button:hover{
  border-radius:.25rem;
}

.main-menu-button.selected{
  border-radius:.25rem;

}

.main-menu-bottom button{
  width: 100%;
  flex: 1;
  height: 100%;
  display:flex;
  flex-direction: column;

}

.main-menu-button button:active,
.main-menu-button button:hover,
.main-menu-button button{
  border-radius:0.25rem;
}

.main-menu-bottom button.selected:hover{
  background-color: inherit;
}

.main-menu-button span{
  font-size:.8rem
}

.main-menu-button svg{
  font-size:2rem
}

@media screen and (max-width:400px){
  .main-menu-button span{
    font-size:.5rem
  }
  
  .main-menu-button svg{
    font-size:2rem
  }
}

.userText{
  color:white;
  display: flex;
  justify-content: center;
  padding: 0 1rem
}

@media screen and (max-height:734px){
  .main-menu-button span{
    font-size:.7rem
  }
  
  .main-menu-button svg{
    font-size:1.5rem
  }
}

@media screen and (max-height:634px){
  .main-menu-button span{
    font-size:.6rem
  }
  
  .main-menu-button svg{
    font-size:1rem
  }
}

@media screen and (max-height:560px){
  .main-menu-button span{
    font-size:.5rem
  }
  
  .main-menu-button svg{
    font-size:.9rem
  }
}