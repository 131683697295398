.enterprise-logobox{
    max-width: 20rem;
    min-width: 20rem;
    min-height: 20rem;
    max-height: 20rem;
    padding: 1rem;
    border: 1px solid rgb(173, 173, 173);
    position:relative;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.enterprise-logobox img{
    width: 100%;
    height: 100%
}

.enterprise-logo-label{
    position: absolute;
    top: -0.7rem;
    font-size: 0.85rem;
    background: white;
    left: 0.6rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 0.3rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
}

.enterprise-logo-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: #0000006b;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    display:flex;
    color: white;
    opacity: 0;
    transition: opacity .5s;
}

.enterprise-logo-overlay:hover{
    opacity: 1;

}

.item-content-modal > .item-profile-row > div.item-enterprise-row{
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: min-content;
}

.item-enterprise-row > div{
    min-width: 15rem;
    flex: 1;
}
