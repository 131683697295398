.item-content-modal.finance{
    padding: 25px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #bcbcbc;
    background: white;
    border-radius: .25rem;
} 