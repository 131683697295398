.profile-overflow{
    overflow: auto;
    display:flex;
    align-items: start;
    justify-content: flex-start;
}

.adress-box.onProfile{
    background-color: white;
}
.profile-save-button{
    height:3rem
}

.profile-save-button.minimized{
    height: auto;
    max-height: unset;
    min-height: unset;
    min-width: 2rem;
}


.profile-save-button .flexbox{
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size:.7rem
}

.item-content-modal{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #bcbcbc;
    background: white;
    border-radius: .25rem;
}

.item-content-modal.limitedscroll{
    max-height: 10rem;
    overflow: auto
}

.item-padding{
    padding: 1rem 2rem
}