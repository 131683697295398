.email-test-error{
    color: red;
    display: flex;
    -webkit-box-pack: justify;
    text-wrap: balance;
}

.email-test-rows{
    display:flex;
    flex-direction: column;
    gap:1rem
}
.email-flex-header{
    display:flex;
    gap:1rem
}

.email-tls-buttons{
    display: flex;
    border: 1px solid #d0d0d0;
    width: fit-content;
    border-radius: .25rem 
}

.email-tls-buttons button{
    width:5rem
}

.breaking-text-area textarea{
    text-wrap:revert
}