.table-column-popover{
    padding:0.5rem;
    display:flex;
    flex-direction: column;
    width: 20rem;
}

.table-column-list{
    display:flex;
    flex-direction: column;
    padding:.5rem;
    align-items: flex-start;
}

.table-column-popover-footer{
    display:flex;
    margin: 0.5rem;
    margin-bottom: 0.25rem
}

.table-column-popover-footer > button {
    flex: 1;   
}

.table-filter-choose{
    display:flex;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 0.25rem;
    margin: 0.5rem
}

.table-filter-choose > button{
    flex:1
}

span.mobile-secundary-separator > *:not(:first-child)::before,
span > .mobile-title-separator *:not(:first-child)::before {
    content: ", ";
}

button.returnButtonClass {
    min-width: 2rem;
    padding: 0;
    padding-left: 0.7rem;
}