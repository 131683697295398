.main-screen{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
}

.divide-flex{
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}

.default_padding {
    padding: 1rem;
    width: 100%;
    height: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .default_padding{
        gap:1.5rem
    }
}

.catalog_box{
    height: 100svh;
    display: flex;
    flex-direction: column;
    max-height: 100svh;
}

::-webkit-scrollbar{
    width: 5px;
    height: 5px
}

::-webkit-scrollbar-track-piece{
    background: #ffffff00;
}

::-webkit-scrollbar-thumb{
    background: #1A3B4E;
    border-radius: 1rem;
}

.main-screen .realpadding{
    height:100%;
    padding: 0.5rem 1rem
}

.MuiDataGrid-main {
    font-size: 1.1rem;
}

.default-column-spacing > .MuiDataGrid-cell{
    overflow: auto
}

.MuiDataGrid-footerContainer p{
    margin: 0
}
.MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-columnHeaderTitle{
    font-weight: bold;
    font-size: 1.2em;
}

.MuiDataGrid-root{
    background-color: white;
}

Button{
    height:100%
}

@media screen and (max-width:769px) {
    .readableFont{
        font-size:.75rem
    }
    
}

