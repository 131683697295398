.report-main-table {
    overflow: auto;
    height: 100%;
}

.report-main-screen{
    flex: 1 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: white;
}

.report-resume-row,
.report-main-row,
.report-main-headers {
    display: flex;
}

.report-main-headers{
    font-weight: bold;
    border-bottom: 2px solid black;
    padding-bottom: .5rem;
}

.report-main-title {
    border-top: 2px solid black;
    padding: 1rem 0rem;
}

.report-extra-info,
.report-resume-row {
    background: #dedede;
}

.report-resume-row{
    border-top: 2px solid black;
    font-weight: bold;
}

.report-main-cell{
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}